.hero_section {
  background-image: url("../assets/hero_section.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.tabs {
  color: #fff;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
