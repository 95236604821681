@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-prev {
  left: 1% !important;
  z-index: 1;
  color: rgb(0, 200, 255);
}

.slick-next {
  right: 1% !important;
  z-index: 1;
}
.slick-prev::before {
  color: rgb(0, 200, 255) !important;
  font-size: 30px !important;
}
.slick-next::before {
  color: rgb(0, 200, 255) !important;
  font-size: 30px !important;
}
