.inputs_design {
  padding: 15px 15px;
  font-size: 14px;
  color: #fff;
  width: 100%;
  letter-spacing: 1px;
  border: 1px solid rgb(83, 178, 246);
  border-radius: 0.4rem;
  color: rgb(83, 178, 246);
}
.textarea_design {
  min-height: 150px;
  resize: none;
  border-radius: 0.25rem;
  overflow: auto;
}
.submit_button {
  display: block;
  outline: none;
  padding: 0.5em 2em;
  font-size: 16px;
  color: #fff;
  background: #0ea4e8;
  border: none;
  border-radius: 50px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: 0.5s all;
  font-weight: 600;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  margin: 15px auto;
}

/* collapse css */
.ReactCollapse--collapse {
  transition: height 500ms;
}

/* notice css */
.marquee-container {
  overflow: hidden;
  position: relative;
}

.marquee-content {
  position: absolute;
  bottom: -150%;
  animation: marqueeAnimation 20000ms linear infinite;
}

.hovered .marquee-content {
  animation-play-state: paused;
}

@keyframes marqueeAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-280%);
  }
}

/* Print services css */

.print-bg {
  background-image: url("../public/images/print.png");
  width: 100%;
  height: auto;
}
